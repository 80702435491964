<template>
  <div v-loading.fullscreen.lock="loading" class="elv-oauth-container"></div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import ReportsApi from '@/api/ReportsApi'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const loading = ref(true)

onMounted(async () => {
  try {
    if (route.query?.code && route.query?.state) {
      const entityId = String(route.query?.state.split('|')[1])
      const projectId = String(route.query?.state.split('|')[2])

      const params = {
        code: route.query?.code,
        redirectUri: window.location.href.split('?')[0],
        type: 'XERO'
      }
      await ReportsApi.getEntityAuthErp(entityId, params)
      ElMessage.success(t('message.connectedSuccessfully', { platform: 'Xero' }))
      router.replace({ name: 'project-settings', params: { projectId }, query: { entityId } })
    }
  } catch (error: any) {
    ElMessage.error(error?.message)
    console.log(error)
    const entityId = String(route.query?.state?.split('|')[1])
    const projectId = String(route.query?.state?.split('|')[2])
    router.replace({ name: 'project-settings', params: { projectId }, query: { entityId } })
  }
})
</script>

<style lang="scss" scoped>
.elv-oauth-container {
  width: 100vw;
  height: 100vh;
}
</style>
